import {
  bluebird_tech_partner_logo,
  chitle_bandhu_logo,
  delhi_metro_rail_corporation_logo,
  deutsche_bank_logo,
  dhl_emblem_logo,
  energy_and_utility_dashboard,
  energy_dashboard,
  hvac_dashboard,
  iam_logo,
  iam_tech_partner_logo,
  icici_bank_logo,
  idfc_first_bank_logo,
  iesgwhitelogo,
  kiam_logo,
  kiam_tech_partner_logo,
  kmrl_logo,
  krisha_diagnostics_logo,
  ncrtc_logo,
  pune_metro_logo,
  sauthco_logo,
  style_union_logo,
  sustain_analytics_bg_logo,
  sustain_analytics_img,
  sustainshere_img,
  sustainsphere_bg_logo,
  tagid_solutions_tech_partner_logo,
  tailormode_hardware_and_app_bg_logo,
  sustain_connect_img,
  wattwise_bg_logo,
  wattwise_img,
  selec_logo,
  trinity_logo,
  easy_io_logo,
  breathe_esg_logo,
  escalator_dashboard,
  mcb_dashboard,
  water_dashboard,
  green_light_image,
  group_products_photo,
} from "../Assets";

export const homePageSlidesData = [
  {
    title:
      "Innovate, Monitor and Sustain Your Utilities' Future With Our Expertise",
    subtitle:
      "A centralized data-driven building management platform to help you build a strong sustainable and carbon saving environment and achieve your sustainability goals.",
    image: green_light_image,
    extraInfo: "",
  },
  {
    title: "IOT BASED",
    subtitle: "Utility Monitoring System",
    image: group_products_photo,
    extraInfo: "Save Power Cost by",
    saveNumbers: "15% to 20%",
  },
];

export const homeHeroTitle =
  "Innovate, Optimize, Sustain Pioneering Sustainability Through IoT Innovations.";

export const homeHeroSubTitle =
  "Benefit from our extensive experience and expertise in developing and implementing state-of-the-art IoT solutions tailored to the unique needs of businesses.";

export const allDashboardData = [
  {
    id: 1,
    name: "Energy & Utility Platform",
    image: energy_and_utility_dashboard,
  },
  {
    id: 2,
    name: "Site Level Platform",
    image: energy_dashboard,
  },
  {
    id: 3,
    name: "HVAC Platform",
    image: hvac_dashboard,
  },

  {
    id: 4,
    name: "Escalator Platform",
    image: escalator_dashboard,
  },
  {
    id: 5,
    name: "MCB Platform",
    image: mcb_dashboard,
  },
  {
    id: 6,
    name: "Water Platform",
    image: water_dashboard,
  },
  // {
  //   id: 7,
  //   name: "Energy & Utility Dashboard",
  //   image: energy_and_utility_dashboard,
  // },
];

export const measuringManagingReportingDescription =
  " Simplify the entire process of measuring, managing, and reporting your utility health, anomalies, asset status and energy logs - all on a single platform. Streamline efforts to track and enhance your environmental, social, and governance initiatives across enterprises, portfolios, and organizations.";

export const empoweringSustaibilityDescription = `As a leading force in the realm of the Internet of Things (IoT), we specialize in providing cutting-edge solutions that not only propel businesses forward but also contribute to a greener, more sustainable future.`;

export const empoweringSustaibilityListData = [
  {
    id: 1,
    logo: iesgwhitelogo,
    name: "IESG",
  },
  {
    id: 2,
    logo: iesgwhitelogo,
    name: "Dashboard",
  },
  {
    id: 3,
    logo: iesgwhitelogo,
    name: "Energy & Utilities",
  },
  {
    id: 4,
    logo: iesgwhitelogo,
    name: "HVAC",
  },
  {
    id: 5,
    logo: iesgwhitelogo,
    name: "Reports",
  },
  {
    id: 6,
    logo: iesgwhitelogo,
    name: "Alerts",
  },
  {
    id: 6,
    logo: iesgwhitelogo,
    name: "Settings",
  },
];

export const companyGrowthFigures = [
  {
    id: "happy-customers",
    title: "Happy Customers",
    count: 25,
    abbreviation: "",
    animation_duration: 1000,
  },
  {
    id: "years-of-experience",
    title: "Years of Experience",
    count: 5,
    abbreviation: "",
    animation_duration: 2000,
  },
  {
    id: "energy-saved",
    title: "Energy Saved",
    abbreviation: "Mn KWh",
    count: 20,
    animation_duration: 1000,
  },
  {
    id: "active-sites",
    title: "Active Sites",
    count: 1,
    abbreviation: "k",
    animation_duration: 1000,
  },
  // {
  //   id: "solutions",
  //   title: "Solutions",
  //   count: 100,
  //   animation_duration: 2000,
  // },
  // {
  //   id: "hardware-deployed",
  //   title: "Hardware Deployed",
  //   count: 1000,
  //   animation_duration: 2500,
  // },
];

export const iesgOneTitle = "IESG  -  iONE";
export const iesgOneSubTitle = "Gateway";
export const iesgOneDescription =
  "An IoT gateway platform that transforms the way you connect, monitor, and manage your assets & utilities.";

export const iotSolutionsListData = [
  {
    id: "sustain-sphere",
    serviceType: {
      id: "platform-services",
      label: "Platform Services",
    },
    title: "SustainSphere",
    subtitle: "(Enterprise Application Services)",
    description: [
      "Monitor, Control, Report IoT based smart asset monitoring and control, SustainSphere seamlessly connects to a diverse range of assets, providing measurable data and insights on a single platform Comprehensive features designed to optimize energy consumption, reduce costs, and minimize environmental impact.",
    ],
    description_bg_logo: sustainsphere_bg_logo,
    color: "#0F2229",
    image: sustainshere_img,
  },
  {
    id: "watt-wise",
    title: "Watt-Wise",
    subtitle: "(Monitoring Services)",
    serviceType: {
      id: "platform-services",
      label: "Platform Services",
    },
    description: [
      "Monitor and Report",
      "Track energy consumption and operational parameters at the device level, giving you unprecedented visibility into your operations.",
      "Bring in savings by SOP based implementation.",
    ],
    description_bg_logo: wattwise_bg_logo,
    color: "#282C52",
    image: wattwise_img,
  },
  {
    id: "sustain-analytics",
    title: "SustainAnalytics",
    subtitle: "(Data Analytics)",
    serviceType: {
      id: "platform-services",
      label: "Platform Services",
    },
    description: [
      " Analytics based web and reporting service ",
      " Dedicated data teams identify deviations from normal energy consumption patterns, enabling swift corrective action. ",
      "Identify potential equipment issues early on, preventing costly downtime and repairs.",
    ],
    description_bg_logo: sustain_analytics_bg_logo,
    color: "#1E374B",
    image: sustain_analytics_img,
  },
  {
    id: "sustain-connect",
    title: "SustainConnect",
    subtitle: "(Hardware)",
    serviceType: {
      id: "hardware-services",
      label: "Hardware Services",
    },
    description: [
      "In House designed, manufactured and quality assured hardware.",
      "IoT gateway (iONE) - cloud connectivity to 12 modbus points",
      "IO Panels - asset offline status",
      "Wireless IR Control Hubs - control your ACs and VRF wirelessly.",
      "TH sensors - provide the feel temperature and humidity",
    ],
    description_bg_logo: tailormode_hardware_and_app_bg_logo,
    color: "#0F2229",
    image: sustain_connect_img,
  },
];

export const valuedByCompaniesData = [
  {
    id: "metro",
    categoryTitle: "Metro",
    companies: [
      {
        company_id: "kochi",
        company_name: "Kochi Metro Rail Ltd",
        company_logo: kmrl_logo,
      },
      {
        company_id: "delhi",
        company_name: "Delhi Metro Rail Ltd",
        company_logo: delhi_metro_rail_corporation_logo,
      },
      {
        company_id: "ncrtc",
        company_name: "NCRTC",
        company_logo: ncrtc_logo,
      },
      {
        company_id: "pune",
        company_name: "Pune Metro Ltd",
        company_logo: pune_metro_logo,
      },
    ],
  },
  {
    id: "bfsi",
    categoryTitle: "BFSI",
    companies: [
      {
        company_id: "icici_bank",
        company_name: "ICICI Bank Ltd",
        company_logo: icici_bank_logo,
      },
      {
        company_id: "idfc_first_bank",
        company_name: "IDFC First Bank Ltd",
        company_logo: idfc_first_bank_logo,
      },
      {
        company_id: "deutsche_bank",
        company_name: "Deutsche Bank Ltd",
        company_logo: deutsche_bank_logo,
      },
    ],
  },
  {
    id: "Retail",
    categoryTitle: "Retail",
    companies: [
      {
        company_id: "chitle_bandhu",
        company_name: "Chitle Bandhu Ltd",
        company_logo: chitle_bandhu_logo,
      },
      {
        company_id: "style_union",
        company_name: "Style Union Ltd",
        company_logo: style_union_logo,
      },
    ],
  },
  {
    id: "warehouse",
    categoryTitle: "Warehouse",
    companies: [
      {
        company_id: "dhl",
        company_name: "DHL-Emblem Ltd",
        company_logo: dhl_emblem_logo,
      },
      {
        company_id: "sauthco",
        company_name: "Sauthco Ltd",
        company_logo: sauthco_logo,
      },
    ],
  },
  {
    id: "path_labs",
    categoryTitle: "Path Labs",
    companies: [
      {
        company_id: "krisha_diagnostics_logo",
        company_name: "Krisha Diagnostics Ltd",
        company_logo: krisha_diagnostics_logo,
      },
    ],
  },
];

export const endorsementsByCompaniesData = [
  {
    comapany_id: "iam",
    company_name: "IAM",
    comment:
      '"Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future."',
    company_logo: iam_logo,
  },
  {
    comapany_id: "kiam",
    company_name: "KIAM",
    comment:
      '"Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future."',
    company_logo: kiam_logo,
  },
  {
    comapany_id: "iam",
    company_name: "IAM",
    comment:
      '"Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future."',
    company_logo: iam_logo,
  },
  {
    comapany_id: "kiam",
    company_name: "KIAM",
    comment:
      '"Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future."',
    company_logo: kiam_logo,
  },
];

export const integrationPartnersData = [
  // {
  //   id: "iam",
  //   company_name: "IAM",
  //   company_logo: iam_logo,
  // },
  {
    id: "kiam",
    company_name: "KIAM",
    company_logo: kiam_logo,
  },
];

export const technologyPartnersData = [
  // {
  //   id: "iam",
  //   company_name: "IAM",
  //   company_logo: iam_tech_partner_logo,
  // },
  // {
  //   id: "selec",
  //   company_name: "Selec",
  //   company_logo: selec_logo,
  // },
  {
    id: "trinity",
    company_name: "Trinity",
    company_logo: trinity_logo,
  },
  {
    id: "easyio",
    company_name: "EasyIO",
    company_logo: easy_io_logo,
  },
];

export const cmsPartnersData = [
  {
    id: "iam",
    company_name: "IAM",
    company_logo: iam_tech_partner_logo,
  },
];

export const esgPlatAndPracData = [
  {
    id: "breath-esg",
    company_name: "BreathEsg",
    company_logo: breathe_esg_logo,
  },
];
