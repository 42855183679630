import React, { useEffect, useRef, useState } from "react";
import { styles } from "../../../styles";
import {
  boardMembersInfo,
  coreTeamMembersInfo,
  teamInfoDescription,
  teamInfoTitle,
} from "../../../Constants/AboutPageConstants";
import { gsap, useGSAP, ScrollTrigger } from "../../../Constants";
import { iesg_website_video } from "../../../Assets";
import Video from "./Video";

gsap.registerPlugin(ScrollTrigger);

const TeamInfo = () => {
  useGSAP(() => {
    gsap.fromTo(
      "#board-members",
      {
        scale: 0,
      },
      {
        scale: 1,
        duration: 2,
        stagger: {
          amount: 1,
          from: "start",
          axis: "x",
        },
        scrollTrigger: {
          trigger: "#board-members",
        },
      }
    );
    gsap.fromTo(
      "#team-info-description",
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        duration: 2,
        ease: "power1.inOut",
        y: 0,
        scrollTrigger: {
          trigger: "#team-info-description",
        },
      }
    );
  }, []);

  const videoRef = useRef(null);

  useEffect(() => {
    const handleLoad = () => {
      if (videoRef.current) {
        // Access the contentWindow of the nested iframe
        videoRef.current.contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          "*"
        );
      }
    };

    // Attach the handleLoad function to the onLoad event of the iframe
    if (videoRef.current) {
      videoRef.current.addEventListener("load", handleLoad);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("load", handleLoad);
      }
    };
  }, []);

  return (
    <div
      className={`${styles.paddingX} py-4 w-full ${styles.flexColNWJCIC} mx-auto `}
    >
      <p
        className={`${styles.sectionHeadText} text-center mt-[2.4rem] w-[60%]`}
      >
        {teamInfoTitle}
      </p>
      <div className={`${styles.flexRowWJCIS} mt-[4rem]  gap-[3rem]`}>
        {boardMembersInfo?.map((member, index) => (
          <div
            className={`${styles.flexColNWJCIC}`}
            id="board-members"
            key={index}
          >
            <img
              src={member?.profile_photo}
              alt={member?.name}
              className="rounded-full"
            />
            <p
              className={`text-center text-[1rem] font-semibold mt-[1.4rem] uppercase`}
            >
              {member?.name}
            </p>
            <p
              className={`text-center text-[1rem] my-[0.6rem] font-semibold uppercase`}
            >
              {member?.designation}
            </p>
            {member?.social_media_info?.length > 0 && (
              <div className={`${styles.flexRowWJCIC} gap-[0.5rem]`}>
                {member?.social_media_info?.map((platform, index) => (
                  <a key={index} target="_blank" href={platform?.platformLink}>
                    <img
                      src={platform?.platformLogo}
                      alt={platform?.platformName}
                      className="w-[2.5rem] hover:scale-125 duration-200"
                    />
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {coreTeamMembersInfo?.length > 0 ? (
        <div className={`${styles.flexColNWJCIC} w-full mt-[3.5rem]`}>
          <p className={`${styles.sectionHeadText} text-center w-[60%]`}>
            CORE TEAM
          </p>
          <div
            className={`flex flex-col lg:flex-row flex-nowrap lg:flex-wrap justify-center items-center gap-[2rem] lg:gap-[0rem] mt-[3rem] core-team`}
          >
            {coreTeamMembersInfo?.map((member, index) => (
              <div
                className={` lg:${styles.flexRowNWJCIC} core-team-member`}
                key={index}
              >
                <img
                  src={member?.profile_photo}
                  alt={member?.name}
                  className="rounded-full core-team-member-img"
                />
                <div
                  className={`${
                    index !== coreTeamMembersInfo?.length - 1
                      ? "curved-dash-line"
                      : "null"
                  } hidden lg:flex`}
                ></div>
                {/* <p
              className={`text-center text-[1rem] font-semibold mt-[1.4rem] uppercase`}
            >
              {member?.name}
            </p>
            <p
              className={`text-center text-[1rem] my-[0.6rem] font-semibold uppercase`}
            >
              {member?.designation}
            </p>
            <div className={`${styles.flexRowWJCIC} gap-[0.5rem]`}>
              {member?.social_media_info?.map((platform, index) => (
                <a key={index} target="_blank" href={platform?.platformLink}>
                  <img
                    src={platform?.platformLogo}
                    alt={platform?.platformName}
                    className="w-[2.5rem] hover:scale-125 duration-200"
                  />
                </a>
              ))}
            </div> */}
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {/* <img
        src={iesg_team_photo}
        alt="iesg_team_photo"
        className="mt-[4rem] w-[30rem] sm:[40rem] md:w-[50rem]"
      /> */}
      {/* <iframe
        src="https://www.youtube.com/embed/27WjOUsefoQ?si=k_oYtojQQrEnPJrS?autoplay=1" 
        title="YouTube video player"
        // frameborder="0"
        //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; "
        allowfullscreen
        // sandbox="allow-scripts allow-presentation allow-same-origin"
        // allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write"
        // className={`w-[28rem] h-[1rem] lg:w-[52rem] lg:h-[29.2rem] rounded-[1.4rem] my-[6rem]`}
      ></iframe> */}
      {/* <iframe
        src="https://www.youtube.com/embed/27WjOUsefoQ?autoplay=1&si=k_oYtojQQrEnPJrS"
        className={`w-[28rem] h-[1rem] lg:w-[52rem] lg:h-[29.2rem] rounded-[1.4rem] my-[6rem]`}
        title="YouTube video player"
        allowfullscreen
      ></iframe> */}
      {/* <iframe
        ref={videoRef}
        src="https://www.youtube.com/embed/fHUYGu7jJ5s?si=X8Jc0_eboSdJo_X_?autoplay=1&si=k_oYtojQQrEnPJrS"
        className={`w-[24rem] h-[16rem] md:w-[42rem] md:h-[28rem] lg:w-[52rem] lg:h-[29.2rem] rounded-[1.4rem] my-[6rem]`}
        title="YouTube video player"
        allowFullScreen
      ></iframe> */}

      {/* <YoutubeVideoPlay/> */}
      <Video />
    </div>
  );
};

export default TeamInfo;
